import React from "react";
import Layout from "../../layouts";
import Seo from "../../components/Seo";
import IntroBanner from "../../components/IntroBanner";
import chamil from "../../images/chamil.jpg";
import chamil2 from "../../images/chamil2.png";
import chamil3 from "../../images/chamil3.png";
import mvp from "../../images/mvp.png";
import { graphql } from "gatsby";

const SMSBot = (props) => {
    return (
        <Layout bodyClass="page-blog">
            <Seo
                title="New Addition to Salesforce MVP Hall of Fame Sets Global Milestone"
                description="Chamil Madusanka, Head of Salesforce Practice at IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted into the Salesforce MVP Hall of Fame."
                image={chamil}
            />
            <IntroBanner
                title="New Addition to Salesforce MVP Hall of Fame Sets Global Milestone"
                image={chamil}
            />
            <div className="content">
                <div className="content-body">
                    <div className="container pt-4 pt-md-4">
                        <div className="row pb-4">
                            <div className="col">
                            <p>Chamil Madusanka, Head of Salesforce Practice at Australian-based IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted into the Salesforce MVP Hall of Fame.</p>
                
                            <p>Chamil has been leading iTelaSoft’s Salesforce team at the company’s Sri Lankan branch since 2022 and has been supported by iTelaSoft in his continued contributions to the Salesforce community.</p>
                            
                            <p>To be inducted, Salesforce Trailblazers (those who innovate and deliver success with Salesforce) have to be awarded the MVP title five separate times. To become an MVP, Trailblazers are self- or community-nominated and are then evaluated for their expertise, leadership, and generosity in helping others learn.</p>
                            
                            <p>As of today, less than 200 professionals across the globe hold these titles. And notably, Chamil was also the first Sri Lankan to be awarded the MVP title in 2019.</p>
                            
                            <p>With Chamil’s Hall of Fame status, iTelaSoft can bring even more value to clients, including higher priority on support, the ability to work and partner with leading organisations and software firms, and access to further certification and training opportunities.</p>

                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>Pure coincidence behind the inspiring journey</h3>
                
                            <p>Chamil began his journey with Salesforce in 2011 by accident, having applied for a different role and then offered a Salesforce position instead. He immediately embarked on his journey to develop his Salesforce skills, turning to support forums and books.</p>

                            <p>He cites MVP Hall of Famers Ankit Arora, Abhinav Gupta, and Shivanath Devinarayanan as early inspirations.</p>

                            <div className="blog-quote1">
                              <img alt="chamil" src={chamil2} />
                              
                              <p className="blog-quote-txt">“I utilised the support of these MVPs at the beginning of my journey, and I wanted to do the same for others just starting out,” says Chamil. “By supporting others in their aspirations, I found myself achieving dreams I never imagined possible. My journey has been nothing short of extraordinary, filled with learning, growth, and above all, the unwavering support of this amazing community.”</p>
                            </div>

                            <p>Chamil has been the beating heart of the Sri Lankan Salesforce community. He first brought together other professionals in a LinkedIn group, which was soon recognised by Salesforce as Sri Lanka’s first official developer group.</p>

                            <p>Today, six official groups exist across two cities (Colombo and Jaffna), serving developers, women in tech, and admins. All inspired by Chamil’s efforts to grow the community.</p>

                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>Making history as a Salesforce MVP</h3>

                            <img className="mvp-img" alt="mvp" src={mvp} />

                            <p>The first MVP title was award to Chamil in 2019, recognising his incredible contributions to the community. By this time, Chamil had published two books, ran a popular support blog, and was providing career opportunities and mentorship to newbies. He went on to be awarded MVP again the following four years, becoming a Hall of Famer in 2024.</p>

                            <p>Chamil is making history and iTelaSoft is honoured to support him on his journey and have him lead its Salesforce team.</p>

                            <p className="blog-quote-txt my-3">iTelaSoft’s CEO Indaka Raigama says, “Chamil and iTelaSoft have been actively organising and participating in many community events to increase the awareness and competency of Salesforce. I’d like to congratulate Chamil and wish him continued fame of being a respected professional in the community.”</p>

                            <p className="hide-desk">This award has given Chamil the opportunity to speak at international conferences like IndiaDreamin, WomenInTechDreamin and Yarl Dreamin, work together with the same MVPs that inspired his journey, and to continue lifting up the Sri Lankan Salesforce community.</p>

                            <div className="blog-quote2">
                              <div>
                                <p className="hide-moble">This award has given Chamil the opportunity to speak at international conferences like IndiaDreamin, WomenInTechDreamin and Yarl Dreamin, work together with the same MVPs that inspired his journey, and to continue lifting up the Sri Lankan Salesforce community.</p>

                                <div className="blog-quote-txt">
                                  <p>“I’m very proud to receive this honour as the first Sri Lankan. Receiving this recognition is a testament to the dedication and passion of the entire Salesforce Ohana in Sri Lanka,” Chamil says.</p>    
                                  <p>And for others wishing to achieve the same, he shares some advice: “To all those embarking on their own Salesforce journey or any career path for that matter, I say this: keep the passion alive, embrace every opportunity that comes your way, and never hesitate to lend a helping hand to those around you.”</p>
                                </div>
                              </div>

                              <img alt="chamil" src={chamil3} />
                            </div>
                             
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                            <h3>About iTelaSoft</h3>

                            <p>Since 2009, iTelaSoft has been solving complex business problems with cutting-edge technology solutions.</p>

                            <p>Australian based with global experience, we thrive on innovation and delivering real value to our clients. Our talented team of software engineers, designers and developers work with small, mid-size and large enterprise clients from strategy development through to delivery.</p> 

                            <p>We specialise in strategy consultation, innovation incubation, solution design, development and management of products. We enable organisations to advance and maintain innovation and a competitive advantage.</p> 

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query {
    WebComponents: file(relativePath: { eq: "webcomponents.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    UIControls: file(relativePath: { eq: "ui-controls.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SMSBot;
